import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LinkButton from "../../v2023/UI/LinkButton/link-button";
import { Link } from "gatsby";
import Avatar from "../avatar/avatar";
import Tag from "../../v2023/UI/Tag/tag";

export const Tile = ({ title, body, button, smallButton = false, image, purple, whiteNb = false, whiteSoft  = false, buttonAction, bigTitle = false, tag = null }) => {
    let tileClass = 'tile'
    if (purple) {
        tileClass += ' tile--purple'
    }
    if (image) {
        tileClass += ' tile--image'
    }
    if (purple) {
        bigTitle += ' tile--purple'
    }
    if (whiteNb) {
        tileClass += ' tile--white--nb'
    }
    if (whiteSoft) {
        tileClass += ' tile--white--soft'
    }

    return (
        <div className={tileClass}>
            {title && (whiteNb || whiteSoft) && (
                <div className="tile__header">
                    <h4>{title}</h4>
                    {tag && (
                        <div className="tag tag--status">
                            <i className="icon-rocket"></i>{tag.name}
                        </div>
                    )}
                </div>
            )}
            <div className="tile__body">
                {title && !(whiteNb || whiteSoft) && (
                    bigTitle ? (
                        <h2>{title}</h2>
                    ) : (
                        <h3>{title}</h3>
                    )
                )}
                {body && (
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={body} />
                )}
                {button && buttonAction && (
                    <button onClick={buttonAction} className={`button ${smallButton ? 'button--sm' : ''}`}>
                        {button.text}
                    </button>
                )}
                {button && !buttonAction && (
                    <LinkButton button={button} className={`button ${smallButton ? 'button--sm' : ''}`} extras={<i className="icon-arrow-right"></i>} />
                )}
            </div>
            {image && (
                <div className="tile__image">
                    <GatsbyImage
                        alt={image.alternativeText}
                        image={getImage(image.localFile)}
                        placeholder="blurred"
                        quality={100}
                    />
                </div>
            )}
        </div>
    )
}

export const WhiteTileOpinion = ({ logo, opinion, url, urlText, avatar }) => {
    if (avatar) {
        let avatarItem = Array.from(avatar)
        if (avatarItem.length === 0) {
            avatarItem = Object.values(avatar)
        }
        const avatarValues = avatarItem.find(item => {
            return item !== undefined
        })
        if (avatarValues === undefined) {
            avatar = undefined
        }
    }
    return (
        <div className="tile tile--white--opinion">
            <div className="tile__body">
                {logo &&
                    <div className="tile__body__column">
                        <div className="column__image">
                            <GatsbyImage alt={logo.alternativeText} image={getImage(logo.localFile)} />
                        </div>
                    </div>
                }
                <div className="tile__body__column">
                    <ReactMarkdown children={`"${opinion}"`} rehypePlugins={[rehypeRaw]} />
                    {url &&
                        <Link class="link--big" to={url}>{urlText}<i class="icon-arrow-right"></i></Link>
                    }
                    {/* Si esto pasa a ser un componente, también podria tener el componente avatar aqui dentro en vez del link */}
                    {avatar && <Avatar avatar={avatar} /> }
                </div>
            </div>
        </div>
    )
}

export const WhiteTileOpinionVertical = ({ opinion, avatar }) => {
    return (
        <div className="tile tile--white--nb">
            <i className="icon-blockquote i-xl"></i>
            <div className="tile__body">
                <div className="fs-24">
                    <ReactMarkdown children={`"${opinion}"`} rehypePlugins={[rehypeRaw]} />
                </div>
            </div>
            {avatar &&
                <div className="avatar">
                    <div className="avatar__job">
                        <b>name</b>
                        <span>job</span>
                    </div>
                </div>
            }
        </div>
    )
}

export const WhiteTileNb = ({body, avatar}) => {
    return <div className="tile tile--white--nb">
        <i className="icon-blockquote i-xl"></i>
        <div className="tile__body">
            <div className="fs-24">
                {body}
            </div>
        </div>
        {avatar && <Avatar avatar={avatar} />}
    </div>
}

export const GrayTile = ({ title, children, thin }) => {
	let grayTileClass = ['tile tile--gray'];
	if (thin) {
		grayTileClass.push('tile--gray-thin');
	}

	return (
		<div className={grayTileClass.join(' ')}>
            {title && <h2>{title}</h2>}
			<div className="tile__body">
				{children}
			</div>
		</div>
	);
};