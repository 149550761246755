import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import CardEvent from '../../v2023/UI/Card/card-event';
import CardPost from '../../v2023/UI/Card/card-post';
import { WhiteTileOpinion } from '../tile/tile';

const normalizeSlidesCardPost = (slides) => slides.map(normalizeSlideCardPost);

const normalizeSlideCardPost = (slide) => ({
	title: slide.node?.name || slide.node?.title || slide.title,
	message: slide.node?.seo?.meta_description || slide.description,
	image: slide.node?.thumbnail_2 || slide.node?.seo?.image_2 || slide.image,
	url: slide.node?.url || slide.case_study?.url,
});

const normalizeSlidesTestimonial = (slides) => slides.map(normalizeSlideTestimonial);

const normalizeSlideTestimonial = (slide) => ({
	message: slide.node?.message || slide.node?.description || slide.opinion?.message,
	image: slide.node?.company_logo_2 || slide.node?.media?.media || slide.opinion?.company_logo_2,
	avatar: {
		name: slide.node?.name || slide.opinion?.name,
		department: slide.node?.job_position || slide.opinion?.job_position,
	}
});

export const CarouselTouch = ({
	slides,
	lang,
	showArrows = false,
	showStatus = false,
	showThumbs = false,
	showIndicators = false,
	hideShowMore = false,
	useCardPost = false,
	useCardEvent = false,
	useTestimonial = false,
}) => {
	const [carouselIndicator, setCarouselIndicator] = useState(0);

	const nextCarousel = () => {
		if (carouselIndicator === slides.length - 1) {
			setCarouselIndicator(0);
			return;
		}

		setCarouselIndicator(carouselIndicator + 1);
	};

	const prevCarousel = () => {
		if (carouselIndicator === 0) {
			setCarouselIndicator(slides.length - 1);
			return;
		}

		setCarouselIndicator(carouselIndicator - 1);
	};

	return (
		<div className="carrousel carrousel--touch">
			<div className="carrousel__content">
				{/* Flecha de diapositiva a anterior */}
				{showArrows && (
					<div className="carrousel__content__button carrousel__content__button--prev">
						<button className="button-icon button-icon--secondary button-icon--lg" onClick={prevCarousel}>
							<i className="icon-arrow-left"></i>
						</button>
					</div>
				)}

				{/* Contenido del carousel */}
				<div className="carrousel__content__box">
					<Carousel
						showArrows={false}
						showStatus={false}
						showThumbs={false}
						showIndicators={false}
						selectedItem={carouselIndicator}
						onChange={(index) => setCarouselIndicator(index)}
					>
						{generateCarouselContent(
							slides,
							carouselIndicator,
							useCardPost,
							useCardEvent,
							useTestimonial,
							hideShowMore,
							lang,
						)}
					</Carousel>
				</div>

				{/* Flecha de diapositiva a siguiente */}
				{showArrows && (
					<div className="carrousel__content__button  carrousel__content__button--next">
						<button className="button-icon button-icon--secondary button-icon--lg" onClick={nextCarousel}>
							<i className="icon-arrow-right"></i>
						</button>
					</div>
				)}
			</div>

			{/* Puntos de indicador de posición */}
			{showIndicators && (
				<div className="carrousel__dots">
					{slides.map((item, index) => {
						return (
							<a
								className={`dots__dot ${carouselIndicator === index ? 'dots__dot--active' : ''}`}
								key={index}
								onClick={() => setCarouselIndicator(index)}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};

const generateCarouselContent = (slides, selectedIndex, useCardPost, useCardEvent, useTestimonial, hideShowMore, lang) => {
	if (useCardPost) {
		const normalizedSlides = normalizeSlidesCardPost(slides);
		// Card Post
		return normalizedSlides.map((slide, index) => {
			const showMore = slide.url && !hideShowMore ? true : false
			return <CardPost key={index} name={slide.title} description={slide.message} image={slide.image} url={slide.url} showMore={showMore} lang={lang} horizontal />
		}
	);
	} else if (useCardEvent) {
		// Card Event
		return slides.map((slide, index) => (
			<CardEvent key={index} lang={lang} event={slide.node} showDescription={true} horizontal={true} />
		));
	} else if (useTestimonial) {
		const normalizedSlides = normalizeSlidesTestimonial(slides);
		// Clippings
		return normalizedSlides.map((slide, index) => (
			<WhiteTileOpinion
				key={index}
				opinion={slide.message}
				avatar={slide.avatar}
				logo={slide.image}
			/>
		));
	} else {
		// White Tile Opinion
		return slides.map((slide, index) => (
			<WhiteTileOpinion
				key={index}
				opinion={slide.message}
				logo={slide.image}
				url={slide.url}
				urlText={slide.knowMore}
			/>
		));
	}
};

const Testimonial = ({ message, image, selectedIndex, index }) => {
	return (
		<div className={`box__testimonial ${selectedIndex === index ? 'box__testimonial--show' : ''}`} key={index}>
			{image && (
				<div className="box__testimonial__image">
					<GatsbyImage
						alt={image.alternativeText}
						image={getImage(image.localFile)}
						style={{ maxHeight: 224, maxWidth: 280 }}
					/>
				</div>
			)}
			<div className="box__testimonial__text">
				<div className="text__customer">“{message}”</div>
			</div>
		</div>
	);

	// Original:
	// <div className="carrousel">
	//             <div className="carrousel__content">
	//                 <div className="carrousel__content__button">
	//                     <button className="button-icon button-icon--secondary button-icon--lg" disabled={!prevClippingAvailable} onClick={prevClipping}>
	//                         <i className="icon-arrow-left"></i>
	//                     </button>
	//                 </div>

	//                 <div className="carrousel__content__box">
	//                     {clippings.map((item, index) => {
	//                         return <div className={`box__testimonial ${selectedClippingIndex === index ? 'box__testimonial--show' : ''}`} key={index}>
	//                             <div className="box__testimonial__image">
	//                                 <GatsbyImage alt={item.node.media.media.alternativeText} image={getImage(item.node.media.media.localFile)} style={{maxHeight: 224, maxWidth: 280}} />
	//                             </div>
	//                             <div className="box__testimonial__text">
	//                                 <div className="text__customer">
	//                                     “{item.node.description}”
	//                                 </div>
	//                             </div>
	//                         </div>
	//                     })}
	//                 </div>

	//                 <div className="carrousel__content__button">
	//                     <button className="button-icon button-icon--secondary button-icon--lg" disabled={!nextClippingAvailable} onClick={nextClipping}>
	//                         <i className="icon-arrow-right"></i>
	//                     </button>
	//                 </div>
	//             </div>
	//             <div className="carrousel__dots">
	//                 {clippings.map((item, index) => {
	//                     return <a className={`dots__dot ${selectedClippingIndex === index ? 'dots__dot--active' : ''}`} key={index} onClick={() => selectClippingIndex(index)}/>
	//                 })}
	//             </div>
	//         </div>
};
