import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Avatar = ({ avatar, vertical, lg }) => {
  if (avatar.whatsapp) {
    // Si le pasamos prefijo, se lo ponemos
    if (avatar.whatsapp_prefix) {
      avatar.whatsapp = avatar.whatsapp_prefix.toString() + avatar.whatsapp.toString()
    }
    // Según la documentación de WhatsApp hay que quitar los 0 o los +
    avatar.whatsapp = avatar.whatsapp.replace("+", "").replace('00', '')
  }

  // Si le pasamos el prefijo, lo ponemos
  if (avatar.phonePrefix){
    avatar.phone = avatar.phonePrefix.toString() + avatar.phone.toString()
  }

	let avatarClassName = 'avatar';
	if (vertical) {
		avatarClassName += ' avatar--vertical';
	}
	if (lg) {
		avatarClassName += ' avatar--lg';
	}

  return (
    <div className={avatarClassName}>
      {/* Avatar */}
      {avatar.avatar !== undefined && (
        <div className="avatar__photo">
          <GatsbyImage
            alt={avatar.avatar.alternativeText}
            image={getImage(avatar.avatar.localFile)}
            placeholder="blurred"
            quality={100}
          />
        </div>
      )
      }
      {/* Job */}
      <div className="avatar__job">
        <b>{avatar.name}</b>
        {avatar.department !== undefined &&
          <span>{avatar.department}</span>
        }
      </div>
      {/* Social media */}
      {vertical && (
        avatar.linkedin !== undefined ||
        avatar.mail !== "" ||
        avatar.whatsapp !== "0" ||
        avatar.phone !== "0"
      ) && (
          <div className="avatar__social-media">
            {avatar.linkedin !== undefined && (
                <div className="avatar__social-media__item">
                    <a href={avatar.linkedin} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-linkedin-filled"></i>
                        </button>
                    </a>
                </div>
            )}
            {avatar.mail !== "" && (
                <div className="avatar__social-media__item">
                    <a href={`mailto: ${avatar.mail}`} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-mail"></i>
                        </button>
                    </a>
                </div>
            )}
            {avatar.whatsapp !== "0" && (
                <div className="avatar__social-media__item">
                    <a href={`https://wa.me/${avatar.whatsapp}`} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-whatsapp"></i>
                        </button>
                    </a>
                </div>
            )}
            {avatar.phone !== "0" && (
                <div className="avatar__social-media__item">
                    <a href={`tel: ${avatar.phone}`} target="_blank">
                        <button className="button-icon button-icon--sm">
                            <i class="icon-phone"></i>
                        </button>
                    </a>
                </div>
            )}
          </div>
        )}
    </div>
  )
}

export default Avatar
